:root {
  --blue-1: #88adfb;
  --orange-1: #ff934f;
  --orange-3: #ffc099;
  --orange-2: #ffa770;
  --yellow-1: #ffc857;
  --yellow-2: #ffd685;
  --purple-1: #a292f2;
  --purple-2: #c1b6f6;
  --bonver-green-dim-1: #328080;
  --bonver-green-primary: #00656c;
  --white: #ffffff;
  --spring-green-primary: #00ff7f;
  --grey-dim-1: #606567;
  --grey-dim-2: #b0b2b3;
  --almost-black: #111111;
  --toastify-toast-width: 360px;
}

html,
body {
  font-family: "DM Sans", sans-serif;
}

body {
  line-height: 1.2;
  letter-spacing: 0.02em;
}

#__next {
  isolation: isolate;
  height: 100%;
}

.Toastify__toast {
  box-shadow: 4px 4px 25px #0000001f;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  border-left: 6px solid var(--bonver-green-primary);
  color: var(--almost-black);
  font-family: inherit;
  display: flex;
  align-items: center;
  padding: 8px 20px;
}

.Toastify__toast-body svg {
  color: var(--bonver-green-primary);
}

.react-skeletor {
  --radius: 12px;
}

/* Date range picker */

.rdrDateDisplayWrapper {
  background-color: transparent;
}

.rdrDateDisplay,
.rdrDateDisplayItem + .rdrDateDisplayItem {
  margin: 0;
}

.rdrDayPassive .rdrDayNumber span {
  color: #b0b2b3;
}

.rdrWeekDay {
  color: #606567;
}

.rdrMonthAndYearWrapper {
  padding: 0;
  height: 48px;
}

.rdrWeekDay {
  width: 45px;
  max-width: 45px;
}

.rdrDay {
  --size: 45px;
  width: var(--size);
  height: var(--size);
}

.rdrStartEdge,
.rdrEndEdge {
  border-radius: 9999px !important;
  top: 5px !important;
  left: 5px !important;
  right: 5px !important;
  bottom: 5px !important;
}

.rdrInRange {
  background-color: transparent;
}

.rdrInRange + .rdrDayNumber span {
  color: #1d2429 !important;
}

.rdrDayNumber {
  color: #1d2429 !important;
}

.rdrNextPrevButton {
  margin: 0;
  background: transparent;
  height: 30px;
  width: 30px;
  border: 1px solid #e2e2e2;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrNextPrevButton {
  margin: 0;
  background: transparent;
}

.rdrNextButton i,
.rdrPprevButton i {
  --size: 8px;
  margin: 0;

  width: var(--size);
  height: var(--size);
  border-width: 2px;
  border-radius: 0;
  border-bottom: none;
  border-left: none;
  border-color: #a7aeb2;
}

.rdrNextButton i {
  margin-left: -2;
  transform: rotate(45deg);
}
.rdrPprevButton i {
  margin-right: -2px;
  transform: rotate(-135deg);
}

.rdrMonthAndYearPickers select {
  padding: 4px 30px 5px 10px;
}

.rdrMonthAndYearPickers select {
  color: #1a1a1a;
}

.rdrMonthAndYearPickers select:hover {
  background-color: rgb(50, 128, 128, 0.1);
}

.rdrMonth {
  padding: 8px;
}

.rdrDateDisplayItem {
  border: 1px solid #dddddd;
  box-shadow: none;
  color: #606567;
}

.rdrDateDisplayItem:first-of-type {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rdrDateDisplayItem:last-of-type {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rdrDateDisplayItem input {
  font-size: 12px;
  color: inherit;
  height: 30px;
}

.rdrDateDisplayItem:first-of-type:not(.rdrDateDisplayItemActive) {
  border-right: none;
}

.rdrDateDisplayItem:last-of-type:not(.rdrDateDisplayItemActive) {
  border-left: none;
}

.rdrDateDisplayItemActive {
  color: #111;
  font-weight: 600;
}

.rdrMonthAndYearPickers {
  font-size: 18px;
}

.rdrDayToday .rdrDayNumber span:after {
  background-color: var(--almost-black) !important;
}

.rsw-editor {
  font-size: 14px;
  font-family: inherit;
  margin-bottom: 12px;
}

.rsw-ce {
  background-color: #f5f5f5;
  min-height: 100px;
}

[data-radix-popper-content-wrapper] {
  z-index: 9 !important;
}
