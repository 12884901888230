.Button {
  --size: 32px;
  border: none;
  background-color: #f7f7f7;
  border-radius: 9999px;
  height: var(--size);
  width: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
}
